export const ValidateForm = (inputValue, FormType, UpdatePassword) => {
  // formType = add , update or updateProfile
  let error = {};
  let isValid = true;
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!inputValue?.name ) {
    error.name = "Bitte überprüfe deine Eingabe";
    isValid = false;
  }
 

  return { isValid, error };
};
