import React from "react";
import { Demo1Dashboard } from "./Demo1Dashboard";
import "react-toastify/dist/ReactToastify.css";
// import { getUserInfo } from "../../../utils/user.util";


export function Dashboard() {
  
  return (
    <>
      <div>
        <Demo1Dashboard />
      </div>
    </>
  );
}
