import React, { useState } from "react";
// import "./CredentialManagement.scss";
import { Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import moment from "moment";
import { update } from "lodash";
// import "./CredentialManagement.scss";
// import { Modal } from "react-bootstrap";
function AddUserDetails(props) {
  const [inputType, setInputType] = useState(false);
  const {
    inputValue,
    handleChange,
    errors,
    formType,
    handleAddNews,
    buttonLoading,

    loading,
    handleOnClose,
  } = props;

  return (
    <>
      <>
        <div className="modal-content">
          <Modal.Header
            className="justify-content-start d-flex align-items-center"
            closeButton
          >
            <Modal.Title style={{ color: "#41624C" }}>
              {formType == "add" ? "Projekt hinzufügen" : "Projekt aktualisieren"}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row pb-5">
              <div className="mr-30 ml-4">
                <Label className="form-label" for="name">
                Projektname <span className="text-danger">*</span>
                </Label>
                <Input
                className="p-3"
                  placeholder="Projektname"
                  name="name"
                  value={inputValue?.name}
                  onChange={(event) => handleChange(event, "input")}
                  invalid={errors?.name && "true"}
                />
                <span className="errors">{errors?.name}</span>
              </div>

             
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="activebutton h-40px"
              onClick={handleOnClose}
              style={{ marginRight: "15px" }}
            >
              Abbrechen
            </Button>
            <Button
              disabled={loading}
              className="addbutton"
              onClick={handleAddNews}
            >
              <span>{formType == "add" ? " Speichern" : "update"}</span>
              {(buttonLoading || loading ) && (
                <div class="h-20px spinner-border text-light w-20px ml-2"></div>
              )}
            </Button>
          </Modal.Footer>
        </div>
      </>
    </>
  );
}

export default AddUserDetails;
