import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ApiGet, ApiPut, ApiDelete } from "../../../helpers/API/ApiData";
import { customStyles } from "../tableStyle";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounceHook";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { Trash } from "react-feather";
import { Button, Modal } from "reactstrap";
import "./FunnelResponse2.scss";
import moment from "moment/moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Stepper() {
  const [getNewsData, setGetNewsData] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [close, setclose] = useState(false);
  const [image, setimage] = useState([]);
  const [fileType, setfileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [moralModalClose, setmoralModalClose] = useState(false);
  const [ModalData, setModalData] = useState({});
  const debouncedSearchValue = useDebounce(searchValue, 900);
  const commonField = [
    { key: "Vor_und_Nachname", label: "Vor-und Nachname" },
    { key: "email", label: "E-mail" },
    { key: "Telefon", label: "Telefon" },
  ];
  let lowerflow = [];
  if (ModalData.offerFlow === "Lower Flow") {
    lowerflow = [
      ...commonField,
      { key: "createdAt", label: "Datum" },
      { key: "Nr", label: "Hausnummer" },
      { key: "Ort", label: "Standort" },
      { key: "PLZ", label: "Postleitzahl" },
      { key: "Stromverbrauch", label: "Stromverbrauch" },
      { key: "roofType", label: "Dachtyp" },
    ];
  } else if (ModalData.offerFlow === "upper Flow") {
    if (ModalData.innerFlow === "1") {
      lowerflow = [
        ...commonField,
        { key: "createdAt", label: "Datum" },
        { key: "capacity", label: "Kapazität" },
        { key: "module", label: "Modul" },
        { key: "period", label: "Zeitraum" },
        { key: "photo", label: "Datei" },
        {
          key: "isIncludeGermanGuarantee",
          label: "ist inklusive deutscher Garantie",
        },
        { key: "manufacuresModule", label: "stellt Modul her" },
        { key: "manufacuresSpeicher", label: "Manufacures Speicher" },
        { key: "manufacuresWallbox", label: "Stellt Wallbox her" },
        {
          key: "manufacuresWechselrichter",
          label: "Manufacures Wechselrichter",
        },
        { key: "termOftheGuarantee", label: "Laufzeit der Garantie" },
        { key: "particularImportant", label: "Besonders wichtig" },
        { key: "newOffer", label: "neues Angebot" },
        { key: "important", label: "wichtig" },
        { key: "plannedFacility", label: "Geplante Anlage" },
      ];
    } else if (ModalData.innerFlow === "1 Ja") {
      lowerflow = [
        ...commonField,
        { key: "createdAt", label: "Datum" },
        { key: "capacity", label: "Kapazität" },
        { key: "module", label: "Modul" },
        { key: "period", label: "Zeitraum" },
        { key: "photo", label: "File" },
        {
          key: "isIncludeGermanGuarantee",
          label: "Ist inklusive deutscher Garantie",
        },
        { key: "manufacuresModule", label: "Manufacures Module" },
        { key: "manufacuresSpeicher", label: "Manufacures Speicher" },
        { key: "manufacuresWallbox", label: "Stellt Wallbox her" },
        {
          key: "manufacuresWechselrichter",
          label: "Manufacures Wechselrichter",
        },
        { key: "termOftheGuarantee", label: "Laufzeit der Garantie" },
        { key: "particularImportant", label: "Besonders wichtig" },
        { key: "newOffer", label: "New Offer" },
        { key: "important", label: "Neues Angebot" },
        { key: "plannedFacility", label: "Geplante Einrichtung" },
      ];
    } else if (ModalData.innerFlow === "2") {
      lowerflow = [
        ...commonField,
        { key: "createdAt", label: "Datum" },
        { key: "capacity", label: "Kapazität" },
        { key: "module", label: "Modul" },
        { key: "period", label: "Zeitraum" },
        // { key: "photo", label: "File" },
        {
          key: "isIncludeGermanGuarantee",
          label: "Ist inklusive deutscher Garantie",
        },
        { key: "manufacuresModule", label: "Manufacures Module" },
        { key: "manufacuresSpeicher", label: "Manufacures Speicher" },
        { key: "manufacuresWallbox", label: "Stellt Wallbox her" },
        {
          key: "manufacuresWechselrichter",
          label: "Manufacures Wechselrichter",
        },
        { key: "termOftheGuarantee", label: "Laufzeit der Garantie" },
        // { key: "particularImportant", label: "Particular Important" },
        { key: "newOffer", label: "New Offer" },
        { key: "important", label: "Neues Angebot" },
        { key: "plannedFacility", label: "Geplante Einrichtung" },
      ];
    } else if (ModalData.innerFlow === "2 Ja") {
      lowerflow = [
        ...commonField,
        { key: "createdAt", label: "Datum" },
        { key: "capacity", label: "Kapazität" },
        { key: "module", label: "Modul" },
        { key: "period", label: "Zeitraum" },
        // { key: "photo", label: "File" },
        {
          key: "isIncludeGermanGuarantee",
          label: "Ist inklusive deutscher Garantie",
        },
        { key: "manufacuresModule", label: "Manufacures Module" },
        { key: "manufacuresSpeicher", label: "Manufacures Speicher" },
        { key: "manufacuresWallbox", label: "Stellt Wallbox her" },
        {
          key: "manufacuresWechselrichter",
          label: "Manufacures Wechselrichter",
        },
        {
          key: "termOftheGuarantee",
          label: "Ist inklusive deutscher Garantie",
        },
        // { key: "particularImportant", label: "Particular Important" },
        { key: "newOffer", label: "New Offer" },
        { key: "important", label: "Neues Angebot" },
        { key: "plannedFacility", label: "Geplante Einrichtung" },
      ];
    }
  }

  const renderCustomPrevArrow = (onClickHandler, hasPrev, label) => {
    return (
      <button onClick={onClickHandler} disabled={!hasPrev}>
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </button>
    );
  };

  const renderCustomNextArrow = (onClickHandler, hasNext, label) => {
    return (
      <button onClick={onClickHandler} disabled={!hasNext}>
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
      </button>
    );
  };

  const handleAdditionalInfo = (row) => {
    setmoralModalClose(true);
    setModalData(row);
  };

  const newImagefuntion = (items) => {
    setimage(items);
    setmoralModalClose(!moralModalClose);

    setclose(!close);
  };
  const columns = [
    {
      name: "SNo",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "60px",
    },
    {
      name: "Datum",
      minWidth: "150px",
      selector: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment format="DD-MM-YYYY HH:mm" date={row.createdAt} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: "Vor-und Nachname",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.Vor_und_Nachname ? row.Vor_und_Nachname : "-"}
        </div>
      ),
    },
    {
      name: "E-mail",
      width: "300px",
      selector: (row) => (
        <div className="profileImage">{row.email ? row.email : "-"}</div>
      ),
    },
    {
      minWidth: "180px",
      name: "Kontakt",
      selector: (row) => (
        <div className="profileImage">{row.Telefon ? row.Telefon : "-"} </div>
      ),
    },

    {
      name: "Zusätzliche Details",
      selector: (row) => (
        <div onClick={() => handleAdditionalInfo(row)} className="viewButton">
          <i className="fa fa-eye eye-icon" aria-hidden="true"></i>
        </div>
      ),
    },

    {
      name: "Löschen",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => {
              handleConfirmDelete(row);
            }}
          >
            <Trash className="font-medium-2" />
          </Button>
        </div>
      ),
    },
  ];
  const handleDeleteNewsInfo = async (row) => {
    setLoading(true);
    let response = false;
    await ApiDelete(`straper2/deleteStraper?id=${row?._id}`)
      .then((res) => {
        // toast.success("News deleted successfully");
        setLoading(false);
        handleGetNewsList();
        response = true;
      })
      .catch((err) => {
        setLoading(false);
        // toast.error("Ups, da ist etwas falsch passiert");
      });
    return response;
  };

  const handleConfirmDelete = (row) => {
    return Swal.fire({
      title: "Bist du sicher?",
      text: "Sind Sie sicher, dass Sie Funnel Response 2 löschen möchten?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, löschen Sie es!",
      cancelButtonText: "stornieren",
      customClass: {
        confirmButton: "btn btn-primary mr-10",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const res = await handleDeleteNewsInfo(row);
        if (res) {
          Swal.fire({
            icon: "success",
            title: "Gelöscht!",
            text: "Die Funnel-Antwort 2 wurde gelöscht.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          Swal.fire({
            title: "Abgesagt",
            text: "Löschung abgebrochen!!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Abgesagt",
          text: "Löschung abgebrochen!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        Swal.fire({
          title: "Abgesagt",
          text: "Löschung abgebrochen!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };

  useEffect(() => {
    handleGetNewsList();
  }, [debouncedSearchValue, page, countPerPage]);

  const handleGetNewsList = async () => {
    setLoading(true);
    await ApiGet(
      `straper2/getStraper?page=${page}${
        debouncedSearchValue ? `&search=${searchValue}` : ""
      }&limit=${countPerPage}`
    )
      .then((response) => {
        const data = response?.data?.payload.data;
        setCount(response?.data?.payload.count);

        setGetNewsData(data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };
  const customNoDataComponent = () => (
    <div style={{ textAlign: "center", padding: "10px 0px", fontSize: "16px" }}>
      Aktuell sind keine Daten vorhanden.
    </div>
  );

  return (
    <div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col-12 col-lg-4 d-flex align-items-center">
              <h2 className="pl-3 pt-2">Google Ads & Vergleich</h2>
            </div>
            <div className="col-lg-8 justify-content-end align-items-center row">
              <div className="col col-lg-5">
                <div>
                  <input
                    type="search"
                    className={`form-control form-control-lg form-control-solid `}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setLoading(true);
                    }}
                    name="search"
                    value={searchValue}
                    placeholder="Suche"
                  />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            data={getNewsData}
            columns={columns}
            responsive
            customStyles={customStyles}
            noDataComponent={customNoDataComponent()}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <TailSpin color="#334D52" height={30} width={30} />
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />
        </div>
      </div>

      {moralModalClose && (
        <div className="new__imagemodel">
          <div className="body_model"></div>
          <div className="data_body">
            <div class="profile-card">
              <div className="header">
                <h2 class="profile-name">Funnel Details</h2>
                <p class="profile-email"></p>
                <div className="header_close">
                  <i
                    onClick={() => {
                      setmoralModalClose(!moralModalClose);
                    }}
                    className="fa fa-xs fa-times"
                  ></i>
                </div>
              </div>
              <table>
                {lowerflow.map((item, index) => {
                  let content = ModalData[item.key];
                  if (
                    item.key === "createdAt" &&
                    moment(content, moment.ISO_8601, true).isValid()
                  ) {
                    content = moment(content).format("DD-MM-YYYY");
                  } else if (
                    item.key === "important" &&
                    Array.isArray(content)
                  ) {
                    content = content.join(", ");
                  } else if (
                    item.key === "particularImportant" &&
                    Array.isArray(content)
                  ) {
                    content = content.join(", ");
                  }
                  return (
                    <tr key={index}>
                      <th>{item?.label}</th>
                      <td>:-</td>

                      <td>
                        {item?.key === "photo" ? (
                          <div
                            onClick={() => {
                              newImagefuntion(content);
                            }}
                            className="image"
                          >
                            <i
                              onClick={() => {
                                newImagefuntion(content);
                              }}
                              className="fa fa-eye eye-icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                        ) : (
                          content
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      )}

      {close && (
        <div className="new__imagemodel">
          <div className="new-white-box">
            <div className="body_model">
              <div className="header_close">
                <i
                  onClick={() => {
                    setclose(!close);
                    setimage("");
                  }}
                  className="fas fa-window-close modalClose"
                ></i>
              </div>
            </div>
            <div className="image_bodyy">
              <Carousel
                renderArrowPrev={renderCustomPrevArrow}
                renderArrowNext={renderCustomNextArrow}
                showArrows={true}
              >
                {image.map((ele) => {
                  return (
                    <>
                      <div className="image-div">
                        <img height={"400px"} width={"300px"} src={ele} />
                      </div>
                    </>
                  );
                })}
              </Carousel>
            </div>
            {/* <div className="image_body">
            {fileType == "svg" ||
            fileType == "png" ||
            fileType == "jpg" ||
            fileType == "jpeg" ? (
              <>
                <img src={image} />
              </>
            ) : fileType === "pdf" ? (
              <>
                <object
                  data={image}
                  type="application/pdf"
                  width="420px"
                  height="650px"
                ></object>
              </>
            ) : (
              <></>
            )}
          </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
