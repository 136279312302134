export const ValidateForm = (inputValue, FormType, UpdatePassword) => {
  // formType = add , update or updateProfile
  let error = {};
  let isValid = true;
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!inputValue?.name || inputValue?.name?.trim() === "") {
    error.name = "Bitte überprüfe deine Eingabe";
    isValid = false;
  }
  if (!inputValue?.email || inputValue?.email?.trim() === "") {
    error.email = "Bitte überprüfe deine Eingabe";
    isValid = false;
  } else if (!EMAIL_REGEX.test(inputValue.email)) {
    isValid = false;
    error.email = "Bitte überprüfe deine Eingabe";
  }

  if (!inputValue?.password && (FormType == "add" )) {
    error.password = "Bitte überprüfe deine Eingabe";
    isValid = false;
  }
  if (!inputValue?.projectName ) {
    error.projectName = "Bitte überprüfe deine Eingabe";
    isValid = false;
  }
  if(!inputValue?.uniqueTrackingId){
    error.uniqueTrackingId = "Bitte überprüfe deine Eingabe";
    isValid = false;
  }
  if(!inputValue?.compensation_Team){
    error.compensation_Team = "Bitte überprüfe deine Eingabe";
    isValid = false;
  }
  if(!inputValue?.compensation_Lead){
    error.compensation_Lead = "Bitte überprüfe deine Eingabe";
    isValid = false;
  }
  return { isValid, error };
};
