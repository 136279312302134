import React, {  useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { getUserInfo } from "../../../../utils/user.util";
import "./topbar.scss";

export function Topbar() {
  let userInfo = getUserInfo();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
      viewNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
      viewQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
      viewLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);
  
  

  return (
    <div className="topbar w-100 flex-design">
      <div className="main-navbar-alignment">
        <div className="item-xxx" style={{ fontSize: "14px", fontWeight: "500" }}>
          <span className="menu-text">
            {userInfo?.role == "admin" ? "WEPRO |" : ""} {userInfo?.role == "admin" ? "admin" : `WePro Deustchland GmbH | ${userInfo?.name}`}
          </span>
        </div>
        <div className="justify-content-design">
          <div className="navbar-alignment-new">
            <div style={{ marginRight: "0" }}> {layoutProps.viewUserDisplay && <QuickUserToggler />}</div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
