import React from "react";

import { getUserInfo } from "../../../../utils/user.util";
import { Modal } from "react-bootstrap";
import { Input, Label } from "reactstrap";

function Profile() {
  const userInfo = getUserInfo();

  return (
    <div className="card p-100 " style={{ minHeight: "200px" }}>
      <>
        <Modal.Header>
          <Modal.Title style={{ color: "#41624C" }}> Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div className="col-sm m-5">
              <Label className="form-label" for="Name">
                Nutzer <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="Name"
                name="name"
                value={userInfo?.name}
                disabled
              />
            </div>

            <div className="col-sm m-5">
              <Label className="form-label" for="Number">
                Email <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="Email"
                name="email"
                value={userInfo?.email}
                disabled
              />
            </div>
          </div>
        </Modal.Body>
      </>
    </div>
  );
}

export default Profile;
