import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import "./CredentialManagement.scss";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";

import { customStyles } from "../tableStyle";
import { TailSpin } from "react-loader-spinner";
import { ValidateForm } from "./ValidateForm";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounceHook";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { Trash } from "react-feather";
import { Button } from "reactstrap";
import AddUserDetails from "./AddUserDetails";
import { Modal } from "react-bootstrap";
import { useOnChange } from "../../../hooks/onChangeHooks";

export default function Project() {
  const [getNewsData, setGetNewsData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [isAddUser, setIsAddUser] = useState(false);
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [FormType, setFormType] = useState("");
  const [loading, setLoading] = useState(false);
  const [UpdatePassword, setUpdatePassword] = useState(false);
  const [buttonLoading, setbuttonloading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 900);
  const { inputValue, setInputValue, errors, setErrors, handleChange } =
    useOnChange({});
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "60px",
    },
    {
      name: "Datum",
      width: "210px",
      selector: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment format="DD-MM-YYYY" date={row.createdAt} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: "Projektname",
      selector: (row) => <div className="profileImage">{row.projectname}</div>,
    },

    {
      name: "Aktion",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => updateNewsData(row)}
          >
            <i className="pencilIcon fa fa-pencil" aria-hidden="true"></i>
          </Button>

          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => {
              handleConfirmDelete(row);
            }}
          >
            <Trash className="deleteIcon font-medium-2" />
          </Button>
        </div>
      ),
    },
  ];
  const handleAddProjectClose = () => {
    setIsAddUser(false);
    setInputValue({});
    setErrors({});
    setFormType("add");
    setUpdatePassword(false);
    setbuttonloading(false);
  };

  const updateNewsData = (row) => {
    setInputValue({
      name: row?.projectname,
      id: row?._id,
    });
    setIsAddUser(true);
    setFormType("update");
  };

  const handleValidateForm = () => {
    const error = ValidateForm(inputValue, FormType, UpdatePassword);

    setErrors(error.error);
    return error.isValid;
  };

  const handleAddUser = () => {
    if (handleValidateForm()) {
      setLoading(true);
      let data = {
        projectname: inputValue?.name,
      };
      ApiPost(`project/create`, data)
        .then((res) => {
          toast.success("Benutzer erfolgreich hinzugefügt");
          handleAddProjectClose();
          setLoading(false);
          handleGetNewsList();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
          handleAddProjectClose();
        });
    }
  };
  const handleUpdateUser = (row) => {
    if (handleValidateForm()) {
      setbuttonloading(true);
      let data = {
        projectname: inputValue?.name,
      };
      ApiPut(`project/update?id=${inputValue?.id}`, data)
        .then((res) => {
          toast.success("Benutzer erfolgreich aktualisiert");
          setLoading(false);
          handleAddProjectClose();
          handleGetNewsList();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setbuttonloading(false);
        });
      // if (inputValue?.password && inputValue?.password.trim() !== "") {
      //   data = {
      //     email: inputValue?.email,
      //     password: inputValue?.password,
      //   };

      //   ApiPost(`user/forgot`, data)
      //     .then((res) => {
      //       // toast.success("News updated successfully");
      //       setbuttonloading(false);
      //       handleAddProjectClose();
      //       // handleGetNewsList();
      //     })
      //     .catch((err) => {
      //       toast.error(err?.response?.data?.message);
      //       setbuttonloading(false);
      //     });
      // }
    }
  };

  const handleDeleteNewsInfo = async (row) => {
    setLoading(true);
    let response = false;
    await ApiDelete(`project/deleteUser?id=${row?._id}`)
      .then((res) => {
        setLoading(false);
        handleGetNewsList();
        response = true;
      })
      .catch((err) => {
        setLoading(false);
      });
    return response;
  };

  const handleConfirmDelete = (row) => {
    return Swal.fire({
      title: "Projekt löschen?",
      text: "Bist du sicher, dass du das Projekt löschen möchte?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, löschen!",
      cancelButtonText: "Abbrechen",
      customClass: {
        confirmButton: "btn btn-primary mr-10",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const res = await handleDeleteNewsInfo(row);

        if (res) {
          Swal.fire({
            icon: "success",
            title: "Gelöscht!",
            text: "Projekt erfolgreich gelöscht.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          Swal.fire({
            title: "Abgebrochen",
            text: "Löschung abgebrochen!!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Abgebrochen",
          text: "Löschung abgebrochen!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success bg-green",
          },
        });
      }
    });
  };

  useEffect(() => {
    handleGetNewsList();
  }, [debouncedSearchValue, page, countPerPage]);

  const handleGetNewsList = async () => {
    setLoading(true);

    await ApiGet(
      `project/get?page=${page}${
        debouncedSearchValue ? `&search=${searchValue}` : ""
      }&limit=${countPerPage}`
    )
      .then((response) => {
        const data = response?.data?.payload.data;
        setCount(response?.data?.payload.count);
        setGetNewsData(data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col-12 col-lg-4 d-flex align-items-center">
              <h2 className="pl-3 pt-2">Projekt</h2>
            </div>
            <div className="col-lg-8 justify-content-end align-items-center row">
              <div className="col col-lg-5">
                <div>
                  <input
                    type="search"
                    className={`form-control form-control-lg form-control-solid `}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setLoading(true);
                    }}
                    name="search"
                    value={searchValue}
                    placeholder="Suche"
                  />
                </div>
              </div>
              <div className="cus-medium-button-style button-height pr-2 mt-lg-0">
                <button
                  onClick={() => {
                    setIsAddUser(true);
                    setFormType("add");
                  }}
                  className="btn btn addbutton"
                >
                  Projekt hinzufügen
                </button>
              </div>
            </div>
          </div>
          <DataTable
            data={getNewsData}
            columns={columns}
            responsive
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <TailSpin color="#334D52" height={30} width={30} />
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />
        </div>
        <Modal
          show={isAddUser}
          onHide={handleAddProjectClose}
          className="d-flex justify-content-center align-items-center"
        >
          <AddUserDetails
            UpdatePassword={UpdatePassword}
            setUpdatePassword={setUpdatePassword}
            inputValue={inputValue}
            handleChange={handleChange}
            errors={errors}
            handleAddNews={
              FormType === "add" ? handleAddUser : handleUpdateUser
            }
            setInputValue={setInputValue}
            formType={FormType}
            loading={loading}
            handleOnClose={handleAddProjectClose}
            buttonLoading={buttonLoading}
          />
        </Modal>
      </div>
    </div>
  );
}
