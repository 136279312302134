import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import "./News.scss";
import { ApiGet, ApiPut , ApiDelete } from "../../../helpers/API/ApiData";

import { customStyles } from "../tableStyle";
import { TailSpin } from "react-loader-spinner";

import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounceHook";
import Moment from "react-moment";
import { Button } from "reactstrap";
import { Trash } from "react-feather";
import Swal from "sweetalert2";

export default function Anfragedetails() {
  const [getNewsData, setGetNewsData] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 900);

  const columns = [
    {
      name: "S.No",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "60px",
    },
    {
      name: "Angefragt am",
      minWidth: "150px",
      selector: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment format="DD-MM-YYYY" date={row.createdAt} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: "Region",
      minWidth: "150px",
      selector: (row) => (
        <div className="descriptionDetails">
          {row.description ? row.description : "-"}
        </div>
      ),
    },
    {
      name: "Anfrage für",
      minWidth: "150px",
      selector: (row) => (
        <div className="descriptionDetails">
          {row.contectCategory ? row.contectCategory : "-"}
        </div>
      ),
    },
    {
      name: "Menge",
      selector: (row) => (
        <div className="crowdDetails">{row.crowd ? row.crowd : "-"}</div>
      ),
    },
    {
      name: "Datum",
      minWidth: "200px",
      selector: (row) => (
        <div className="dateData">
          {row.date ? <Moment format="DD-MM-YYYY" date={row.date} /> : "-"}
        </div>
      ),
    },
    {
      name: "Name der Firma",
      minWidth: "150px",
      selector: (row) => (
        <div className="companyName">
          {row.company_name ? row.company_name : "-"}
        </div>
      ),
    },
    {
      name: "Ansprechpartner",
      minWidth: "150px",
      selector: (row) => (
        <div className="contactPerson">
          {row.contact_person ? row.contact_person : "-"}
        </div>
      ),
    },
    {
      name: "E-mail",
      minWidth: "300px",
      selector: (row) => (
        <div className="profileImage">{row.email ? row.email : "-"}</div>
      ),
    },

    {
      name: "Telefon",
      minWidth: "150px",
      selector: (row) => (
        <div className="profileImage">
          {row.telephone ? row.telephone : "-"}
        </div>
      ),
    },
    {
      name: "Löschen",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => {
              handleConfirmDelete(row);
            }}
          >
            <Trash className="font-medium-2" />
          </Button>
        </div>
      ),
    },
  ];

  const handleDeleteNewsInfo = async (row) => {
    setLoading(true);
    let response = false;
    await ApiDelete(`contect/deletecontect?id=${row?._id}`)
      .then((res) => {
        toast.success("News deleted successfully");
        setLoading(false);
        handleGetAnfrangeDetails();
        response = true
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Oop's something wrong happened");
      });
      return response
  };

  const handleConfirmDelete = (row) => {
    return Swal.fire({
      title: "Bist du sicher?",
      text: "Sind Sie sicher, dass Sie die Anfrage löschen Anfrage?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, löschen Sie es!",
      cancelButtonText : "Stornieren",
      customClass: {
        confirmButton: "btn btn-primary mr-10",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
       const res = await handleDeleteNewsInfo(row);
       if(res){
        Swal.fire({
          icon: "success",
          title: "Gelöscht!",
          text: "Anfrage wurde gelöscht.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
       }else{
        Swal.fire({
          icon: "error",
          title: "Abgesagt!",
          text: "Löschung abgebrochen!!.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
       }
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Abgesagt",
          text: "Löschung abgebrochen!!.!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };

  useEffect(() => {
    handleGetAnfrangeDetails();
  }, [debouncedSearchValue, page, countPerPage]);

  const handleGetAnfrangeDetails = async () => {
    setLoading(true);

    await ApiGet(
      `contect/getContect?page=${page}${
        debouncedSearchValue ? `&search=${searchValue}` : ""
      }&limit=${countPerPage}`
    )
      .then((response) => {
        const data = response?.data?.payload.data;

        setCount(response?.data?.payload.count);

        const activeData = data.filter((jobData) => {
          return jobData.isActive;
        });
        setGetNewsData(activeData);
        setLoading(false);
      })
      .catch((error) => {
        // toast.error(error);
        setLoading(false);
      });
  };

  const customNoDataComponent = () => (
    <div style={{ textAlign: 'center', padding: '10px 0px', fontSize: '16px' }}>
      Aktuell sind keine Daten vorhanden.
    </div>
  );

  return (
    <div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col-12 col-lg-4 d-flex align-items-center">
              <h2 className="pl-3 pt-2">Anfrage</h2>
            </div>
            <div className="col-lg-8 justify-content-end align-items-center row">
              <div className="col col-lg-5">
                <div>
                  <input
                    type="search"
                    className={`form-control form-control-lg form-control-solid `}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setLoading(true);
                    }}
                    name="search"
                    value={searchValue}
                    placeholder="Suche"
                  />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            data={getNewsData}
            columns={columns}
            noDataComponent={customNoDataComponent()}
            responsive
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <TailSpin color="#334D52" height={30} width={30} />
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />
        </div>
      </div>
    </div>
  );
}
