import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { DatePickerField } from "../../_partials/controls/forms/DatePickerField";
import "./DateFilter.scss";
import { set } from "lodash";

function DateFilter({ onApply }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const options = [
    { value: "today", label: "Heute" },
    { value: "yesterday", label: "Gestern" },
    { value: "custom", label: "indiv. Datum" },
  ];


  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption && selectedOption.value === "today") {
      const today = new Date();
      setStartDate(today);
      setEndDate(today);
      handleApply(today, today);
    } else if (selectedOption && selectedOption.value === "yesterday") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setStartDate(yesterday);
      setEndDate(yesterday);
      handleApply(yesterday, yesterday);
    } else if (!selectedOption) {
      setStartDate("");
      setEndDate("");
      handleApply(null, null);
    }
  };

  const handleApply = (startDate, endDate) => {
    if (onApply) {
      onApply(selectedOption?.value ?? null, startDate, endDate);
    }
  };

  const handleOnChange = (dates) => {

    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      handleApply(start, end);
    }
  };
  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "150px",
      fontSize: "12px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",

      color: state.isSelected ? "white" : "black", // Change selected color here
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: "150px",

      zIndex: "999", // Adjust font size as needed
    }),
  };

  return (
    <div className="d-flex align-center justify-center Datepicker">
      <div className="mainDivSelect Date-Dropdown">
        <Select
          className="select"
          value={selectedOption}
          onChange={handleOptionChange}
          options={options}
          placeholder="Datum"
          isClearable={true}
          styles={customStyle}
        />
        {/* <i
          onClick={() => handleCloseFilter("projectName")}
          className="cross-icon  fa fa-times"
          aria-hidden="true"
        ></i> */}
      </div>
      {selectedOption?.value === "custom" && (
        <div className={` reactDatePicker`}>
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
             dateFormat="dd/MM/yyyy"
            placeholderText="Date"
            maxDate={new Date()}
            onChange={handleOnChange}
            onKeyDown={(e) => e.preventDefault()}
            selectsRange
            fixedHeight
          />
        </div>
      )}
    </div>
  );
}

export default DateFilter;
