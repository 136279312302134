import React, { useEffect, useState, CSSProperties } from "react";
import "./mitteilungenAdmin.scss";
import Addnotification from "./Addnotification";
import { Modal } from "react-bootstrap";
import { ApiDelete, ApiGet } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import Moment from "react-moment";
import ClipLoader from "react-spinners/ClipLoader";

const Adminmitteilungen = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [currentData, setCurrentData] = useState();
  const [notificationLoader, setNotificationLoader] = useState(false);
  const[isLoading,setIsLoading]=useState(false)
  let [color, setColor] = useState("#0000000");
  const handlenotificationModel = () => {
    setIsModelOpen(!isModelOpen);
  };

  const token = localStorage.getItem("token");
  const getAllNotifications = async () => {
    try {
      if (token) {
        let headers = {
          Authorization: `Bearer ${token}`
        };
        setNotificationLoader(true);
        let resp = await ApiGet(`notification/getAllNotification`, { headers });
        const notificationResData = resp?.data?.payload;
        let oneByoneData = notificationResData.map((item) => {
          return {
            title: item?.title,
            description: item?.description,
            createdAt: item?.createdAt,
            id: item?._id,
            receiver: item?.receiver,
          };
        });
        setNotificationData(oneByoneData);
        setCurrentData(oneByoneData[0]);
        setNotificationLoader(false);
      }
    } catch (error) {
      setNotificationLoader(false);
      toast.error(error);
    }
  };

  const setNotificationDescription = (id) => {
    let currentNotification = notificationData?.find((notification) => {
      return notification.id === id;
    });
    setCurrentData(currentNotification);
  };
  useEffect(() => {
    getAllNotifications();
  }, []);
  const deleteNotification = async (id) => {
    try {
      setIsLoading(true)
      let resp = await ApiDelete(`notification/deleteNotification?id=${id}`)
      if(resp){
        setIsLoading(false)
        toast.success("erfolgreich gelöscht")
        getAllNotifications()
      }
    }
    catch (error) {
      console.log(error)
      setIsLoading(false)

    }
  }
  return (
    <>
      {notificationLoader ? (
        <div className="loader-design">
          <ClipLoader color={color} loading={notificationLoader} size={35} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <div className="mitteilungen-all-contnet-alignment">
          <div className="notification-header-design">
            <div className="text-design">
              <p>Mitteilungen</p>
            </div>
            <div className="add-notification-button">
              <button onClick={handlenotificationModel}>Benachrichtigung senden</button>

            </div>
          </div>

          <div className="grid">
            <div className="grid-items">
              {notificationData?.map((item) => {

                return (
                  <div className={item?.id === currentData?.id ? "sub-box active" : "sub-box"} onClick={() => setNotificationDescription(item.id)}>
                    <div>
                      <p>{item?.title}</p>
                      <span>WePro Admin</span>
                      <h6>
                        {" "}
                        <Moment format="LLL" date={item?.createdAt} tz="CET" />
                      </h6>
                    </div>
                    <i class="fas fa-trash-alt" style={{fontSize:"20px"}} onClick={() => deleteNotification(item?.id)}></i>
                    <div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="grid-items">
              <div className="neues-all-contetent-alignment">
                <div className="add-notification-alignment">

                  <div className="new-item">

                    <button>WePro Admin</button>
                    <span>{currentData?.title}</span></div>

                 
                </div>
              </div>

              <div className="user-data-alignment">
                {currentData &&
                  currentData?.receiver?.map((newitem) => {
                    return (
                      <div className="user-tag-design">
                        <p>{newitem?.name}</p>
                       
                        
                      </div>
                    );
                  })}
              </div>

              <div className="all-list-alignment">
                <p>{currentData?.description}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={isModelOpen} onHide={handlenotificationModel}>
        <Addnotification
          isModelOpen={isModelOpen}
          setIsModelOpen={setIsModelOpen}
          loading={loading}
          setLoading={setLoading}
          getAllNotifications={getAllNotifications}
        />
      </Modal>
    </>
  );
};

export default Adminmitteilungen;
