import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { useOnChange } from "../../../hooks/onChangeHooks";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { customStyles } from "../tableStyle";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import closeIcon from "../../../assets/icon/NicePng_close-button-png_521935.png";

const Addnotification = (props) => {
  const [sendUser, setSendUser] = useState([]);
  const [userOption, setUserOption] = useState([]);
  const [newdata, setNewData] = useState();
  const { inputValue, handleChange, errors, setInputValue, setErrors } = useOnChange();
  const { isModelOpen, setIsModelOpen, loading, setLoading, getAllNotifications } = props;

  const handleGetUserData = async () => {
    setLoading(true);
    await ApiGet(`user/get`)
      .then((response) => {
        const data = response?.data?.payload.data.map((item) => {
          return {
            label: item?.name,
            value: item?._id,
          };
        });
        setUserOption([{ label: "All", value: "all" }, ...data]);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };
  const handleValidation = () => {
    let isValid = true;
    let newerror = {};
    if (!inputValue?.title?.trim()) {
      newerror["title"] = "please enter title";
      isValid = false;
    }
    if (!inputValue?.description?.trim()) {
      newerror["description"] = "please enter description ";
      isValid = false;
    }
    if (inputValue?.receiver?.length <= 0 || !inputValue?.receiver?.length) {
      newerror["notificationtitle"] = "please select User";
      isValid = false;
    }
    setErrors(newerror);
    return isValid;
  };

  const handleOnClose = () => {
    setIsModelOpen(!isModelOpen);
    setInputValue("");
  };

  const handleAddNotifications = async () => {
    if (handleValidation()) {
      const body = {
        title: inputValue?.title,
        description: inputValue?.description,
        receiver: inputValue?.receiver,
      };
      try {
        setLoading(true);
        await ApiPost(`notification/createNotification`, body);
        toast.success("Benachrichtigung erfolgreich gesendet");
        setLoading(false);
        setInputValue("");
        setIsModelOpen(!isModelOpen);
        getAllNotifications();
      } catch (error) {
        toast.error("Etwas ist schief gelaufen");
        setLoading(false);
        setIsModelOpen(!isModelOpen);
      }
    }
  };
  const handleOnChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      let data = userOption.filter((option) => option.value !== "all");
      setSendUser(data);
      setInputValue({
        ...inputValue,
        receiver: data.map((i) => i.value),
      });
      let reciverlabel = data.map((i) => i.label);
      setNewData(reciverlabel);
    } else {
      setSendUser(selectedOptions);
      let data = selectedOptions.map((option) => option.value);
      let labeleddata = selectedOptions.map((option) => option.label);
      setInputValue({ ...inputValue, receiver: data });
      setNewData(labeleddata);
    }
    setErrors({
      ...errors,
      notificationtitle: "",
    });
  };
  useEffect(() => {
    handleGetUserData();
  }, []);
  const handleDeteleName = (item) => {
    let ourFilteresData = newdata.filter((filteritem) => filteritem !== item);
    setNewData(ourFilteresData);
    let filterd = sendUser.filter((newitem) => newitem.label !== item);
    setSendUser(filterd);
  };
  return (
    <>
      <div className="modal-content-design" style={{ width: "900px" }}>
        <Modal.Header className="justify-content-start d-flex align-items-center" closeButton>
          <Modal.Title style={{ color: "#41624C" }}>
            <p>Add Notifications</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row pb-5">
            <div className="col-6 col-md-6">
              <Label className="form-label" htmlFor="name">
                Add Title <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                placeholder="title"
                name="title"
                value={inputValue?.title}
                onChange={(event) => handleChange(event, "input")}
                invalid={errors?.title && "true"}
              />
              <span className="errors">{errors?.title}</span>
            </div>
            <div className="col-6 col-md-6">
              <Label className="form-label" htmlFor="name">
                User <span className="text-danger">*</span>
              </Label>
              <div className="custom-select-design">
                <Select
                  className="dropdown-align"
                  value={sendUser}
                  onChange={handleOnChange}
                  options={userOption}
                  isMulti
                  maxHeight={"100px"}
                  styles={customStyles}
                />
              </div>
              <span className="errors">{errors?.notificationtitle}</span>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-6 col-md-12">
              <div className="user-data-alignment-admnin">
                {inputValue?.receiver &&
                  newdata?.map((item) => {
                    return (
                      <div className="user-tag-design">
                        <p style={{ marginRight: "2px" }}>
                          {item}

                          <svg
                            onClick={() => {
                              handleDeteleName(item);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="white"
                            width="24px"
                            height="24px"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7a.996.996 0 10-1.41 1.41L10.59 12l-4.89 4.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89a.996.996 0 000-1.41z" />
                          </svg>
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-12 col-md-12">
              <Label className="form-label" htmlFor="name">
                Add Details <span className="text-danger">*</span>
              </Label>
              <Input
                type="textarea"
                rows="15"
                placeholder="Enter details"
                name="description"
                value={inputValue?.description}
                onChange={(event) => handleChange(event, "input")}
                invalid={errors?.description && "true"}
              />
              <span className="errors">{errors?.description}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="activebutton h-40px" onClick={handleOnClose} style={{ marginRight: "15px" }}>
            Abbrechen
          </Button>
          <Button disabled={loading} className="addbutton" onClick={handleAddNotifications}>
            <span> Schicken </span>
            {loading && <div className="h-20px spinner-border text-light w-20px ml-2"></div>}
          </Button>
        </Modal.Footer>
      </div>
    </>
  );
};

export default Addnotification;
