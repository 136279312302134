import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { ApiGet, ApiPut, ApiDelete } from "../../../helpers/API/ApiData";
import axios from "axios";
import { customStyles } from "../tableStyle";
import { TailSpin } from "react-loader-spinner";
import "./Stepper.scss";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounceHook";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { Trash } from "react-feather";
import { Button } from "reactstrap";
import Select from "react-select";
import DateFilter from "./DateFilter";
import moment from "moment";
import "moment-timezone";
import { Modal } from "react-bootstrap";

export default function Stepper() {
  const [getNewsData, setGetNewsData] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [filterDataSend, setFilterDataSend] = useState([]);
  const [count, setCount] = useState(0);
  const [close, setclose] = useState("");
  const [image, setimage] = useState("");
  const [fileType, setfileType] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 900);
  const [userName, setUserName] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [statusValue, setStatusValue] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [mobileModel, setMobileModel] = useState(false);
  const [mobileDatas, setMobileDatas] = useState("");

  const commonRequiredfield = {
    productId: 2,
    utm_source: "WePro Deutschland GmbH",
    utm_medium: "affiliate",
    company: "Mustermann GmbH",
  };
  const housetype = {
    Einfamilienhaus: "Ein-/Zweifamilienhaus",
    Reihenhaus: "Ein-/Zweifamilienhaus",
    Doppelhaushälfte: "Ein-/Zweifamilienhaus",
    Mehrfamilienhaus: "Mehrfamilienhaus",
    Garage: "Sonstiges",
    Anderes: "Sonstiges",
  };
  const newImagefuntion = (items) => {
    setimage(items);
    const extension = items.split(".").pop().toLowerCase();

    setfileType(extension);
    if (
      extension == "image" ||
      extension == "pdf" ||
      extension == "png" ||
      extension == "svg" ||
      extension == "jpeg" ||
      extension == "jpg"
    ) {
      setclose(!close);
    } else {
      var link = document.createElement("a");
      link.download = "name";
      link.href = items;
      link.click();
    }
  };

  const handleSelectRow = (row) => {
    if (selectedRows.length == 0) {
      setSelectedRows([row._id]);
    } else if (selectedRows.includes(row._id)) {
      setSelectedRows(selectedRows.filter((item) => item !== row._id));
    } else {
      setSelectedRows([...selectedRows, row._id]);
    }
  };

  const handleSelectAllRows = (checked) => {
    if (checked) {
      const allRowIds = getNewsData
        .map((item) => {
          if (!item.apiAdded) {
            return item._id;
          }
        })
        .filter((id) => id !== undefined);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
  };
  const handleApply = async (selectedOption, startDate, endDate) => {
    if (!startDate || !endDate) {
      handleChangeCommom("", "startDate");
    } else {
      const timezoneOffset = startDate.getTimezoneOffset() * 60000;

      startDate = new Date(startDate - timezoneOffset);
      endDate = new Date(endDate - timezoneOffset);

      startDate = startDate.toISOString().split("T")[0];
      endDate = endDate.toISOString().split("T")[0];

      const params = { ...filterData, startDate: startDate, endDate };
      setFilterData(params);
      handleChangeCommom("", "", params);
    }
  };

  const statusOptions = [
    {
      value: "Open",
      label: "offen",
    },
    {
      value: "Confirmed",
      label: "bestätigt",
    },
    {
      value: "Rejected",
      label: "abgelehnt",
    },
    {
      value:"null",
      label:"eingereicht"
    }
  ];

  const handleOptions = (e) => {
    setStatusValue(e);
  };
  const handleChangeCommom = async (e, key, filter) => {
    let updatedFilter = { ...filterData };

    if (!e && key) {
      if (key == "userId") {
        setSelectedUserName("");
      } else if (key == "projectName") {
        setSelectedProject("");
      }

      if (key === "startDate" || key === "endDate") {
        updatedFilter.startDate = "";
        updatedFilter.endDate = "";
      } else {
        updatedFilter[key] = "";
      }
    } else {
      if (key == "userId") {
        updatedFilter = {
          ...filterData,
          [`${key}[]`]: e.map((item) => item.value),
        };
      } else {
        updatedFilter = key ? { ...filterData, [key]: e.label } : filter;
      }
    }

    setLoading(true);
    let ddd = "";
    for (const [key, value] of Object.entries(updatedFilter)) {
      if (Array.isArray(value)) {
        for (const v of value) {
          ddd += `${key}=${v}&`;
        }
      } else if (value) {
        const data = encodeURIComponent(value);
        ddd += `${key}=${data}&`;
      }
    }

    try {
      const response = await ApiGet(
        `straper/getStraper?${ddd}&limit=${countPerPage}&page=${page}${
          statusValue ? `&status=${statusValue?.value}` : ""
        }${debouncedSearchValue ? `&search=${searchValue}` : ""}`
      );
      const data = response?.data?.payload.data;
      setCount(response?.data?.payload.count);
      if (key == "userId") {
        setSelectedUserName(e);
      } else if (key == "projectName") {
        setSelectedProject(e);
      }
      setGetNewsData(data);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }

    setFilterData(updatedFilter);
  };

  const columns = [
   
    {
      name: "SNo",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "60px",
    },
    {
      name: <span>Datum </span>,
      minWidth: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment
              format="DD-MM-YYYY , HH:mm:ss"
              tz="CET"
              date={row.createdAt}
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: <span>Status</span>,
      width: "150px",
      selector: (row) => {
        const statusClass =
          row?.status == "Open" || !row?.status
            ? "profileImag-pp"
            : row?.status == "Confirmed"
            ? "profileImag-ppp"
            : row?.status == "Rejected"
            ? "profileImag-pppp"
            : "profileImag";

        const statusText =
          row?.status == "Open"
            ? "offen"
            : row?.status == "Confirmed"
            ? "bestätigt"
            : row?.status == "Rejected"
            ? "abgelehnt"
            : row?.status || "eingereicht";

        return <div className={statusClass}>{statusText}</div>;
      },
    },
    {
      name: <span>Unique Id </span>,
      width: "150px",
      selector: (row) => (
        <div className="profileImage">{row.userId ? row.userId : "-"}</div>
      ),
    },

    {
      name: <span>Benutzername </span>,
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.benutzername ? row.benutzername : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: <span>Projektname </span>,
      selector: (row) => <div className="profileImage">{row.projectName}</div>,
    },

    {
      name: "Anrede",
      width: "250px",
      selector: (row) => (
        <div className="profileImage">
          {row.contractions ? row.contractions : "-"}
        </div>
      ),
    },

    {
      name: "Vorname ",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">{row.name ? row.name : "-"}</div>
      ),
    },

    {
      name: " Nachname",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">{row.nachname ? row.nachname : "-"}</div>
      ),
    },

    {
      minWidth: "300px",
      name: " Adresse",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row.strabe ? row.strabe : "-"}
        </div>
      ),
    },

    {
      minWidth: "150px",
      name: "Postleitzahl",
      selector: (row) => (
        <div className="profileImage">{row.pLZ ? row.pLZ : "-"}</div>
      ),
    },

    {
      minWidth: "150px",
      name: "Ort",
      selector: (row) => (
        <div className="profileImage">{row.location ? row.location : "-"}</div>
      ),
    },

    {
      minWidth: "150px",
      name: "Kontakt",
      selector: (row) => (
        <div className="profileImage">{row.telephon ? row.telephon : "-"} </div>
      ),
    },

    {
      name: "E-mail",
      width: "300px",
      selector: (row) => (
        <div className="profileImage">{row.email ? row.email : "-"}</div>
      ),
    },
    {
      name: "Energieverbrauch",
      width: "150px",
      selector: (row) => (
        <div className="profileImage">
          {row.power_consumption ? row.power_consumption : "-"}
        </div>
      ),
    },
    {
      name: "Haustyp",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.housetype ? row.housetype : "-"}
        </div>
      ),
    },
    {
      name: "Dachtyp",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.satteldach ? row.satteldach : "-"}
        </div>
      ),
    },

    {
      name: "Dachbelegung",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">{row.roofFelt ? row.roofFelt : "-"}</div>
      ),
    },
    {
      name: "Störfaktoren",
      minWidth: "250px",

      selector: (row) => (
        <div className="schornstein" style={{ "white-space": "normal" }}>
          {row.schornstein ? row.schornstein.toString() : "-"}
        </div>
      ),
    },
    {
      name: "E-Auto",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.aktuellkeineAuto ? row.aktuellkeineAuto : "-"}
        </div>
      ),
    },

    {
      name: "Wärmepumpe",
      minWidth: "300px",
      selector: (row) => (
        <div className="profileImage">{row.rooftype ? row.rooftype : "-"}</div>
      ),
    },

    {
      minWidth: "300px",
      name: "wichtige Informationen",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row.notizen && row.notizen !== "undefined" ? row.notizen : "-"}
        </div>
      ),
    },

    {
      name: "Unterschrift",
      selector: (row) => (
        <div onClick={() => newImagefuntion(row.photo)} className="profileFoto">
          View
        </div>
      ),
    },

    {
      minWidth: "250px",
      name: "Sales Patners",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row?.patners?.length >0
            ? row?.patners?.map((item) => item.name).join(",")
            : "-"}
        </div>
      ),
    },
    {
      name: <span>Unique Tracking Id</span>,
      width: "150px",
      selector: (row) => (
        <div className="profileImage">
          {row.uniqueTrackingId ? row.uniqueTrackingId : "-"}
        </div>
      ),
    },

    {
      minWidth: "150px",
      name: "Löschen",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => {
              handleConfirmDelete(row);
            }}
          >
            <Trash className="font-medium-2" />
          </Button>
        </div>
      ),
    },
  ];
  const mobileData = [
    {
      name: "SNo",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "40px",
    },
    {
      name: <span>Datum </span>,
      maxWidth: "120px",
      selector: (row) => (
        <div className="profileImage" style={{ wordBreak: "break-word" }}>
          {row.createdAt ? (
            <>
              {" "}
              <Moment
                format="HH:mm:ss DD-MM-YYYY"
                tz="CET"
                date={row.createdAt}
              />
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: <span>Status</span>,
      width: "120px",
      selector: (row) => {
        const statusClass =
          row?.status == "Open" || !row?.status
            ? "profileImag-pp"
            : row?.status == "Confirmed"
            ? "profileImag-ppp"
            : row?.status == "Rejected"
            ? "profileImag-pppp"
            : "profileImag";

        const statusText =
          row?.status == "Open"
            ? "offen"
            : row?.status == "Confirmed"
            ? "bestätigt"
            : row?.status == "Rejected"
            ? "abgelehnt"
            : row?.status || "eingereicht";

        return <div className={statusClass}>{statusText}</div>;
      },
    },
    {
      name: "Löschen",
      width: "80px",
      selector: (row) => (
        <div className="actionColumn">
          <i
            class="fa-solid fa-circle-info" 
            onClick={() => {
              setMobileModel(!mobileModel);
              setMobileDatas(row);
            }}
          ></i>
        </div>
      ),
    },
  ];
  const handleDeleteNewsInfo = async (row) => {
    setLoading(true);
    let response = false;
    await ApiDelete(`straper/deleteStraper?id=${row?._id}`)
      .then((res) => {
        setLoading(false);
        handleChangeCommom("", "", filterData);
        response = true;
      })
      .catch((err) => {
        setLoading(false);
      });
    return response;
  };

  const handleConfirmDelete = (row) => {
    return Swal.fire({
      title: "Bist du sicher?",
      text: "Sind Sie sicher, dass Sie den Lead löschen möchten?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, löschen",
      cancelButtonText: "Abbrechen",
      customClass: {
        confirmButton: "btn btn-primary mr-10",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const res = await handleDeleteNewsInfo(row);
        if (res) {
          Swal.fire({
            icon: "success",
            title: "Gelöscht!",
            text: "Die Lead wurde gelöscht.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          Swal.fire({
            title: "Abgesagt",
            text: "Löschung abgebrochen!!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Abgesagt",
          text: "Löschung abgebrochen!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        Swal.fire({
          title: "Abgesagt",
          text: "Löschung abgebrochen!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };

  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "200px",
      marginLeft: "20px",
      fontSize: "12px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: state.isSelected ? "white" : "black", // Change selected color here
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: "200px",
      marginLeft: "20px",
      zIndex: "999",
    }),
  };
  const ProjectStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "150px",
      fontSize: "12px",
      marginLeft: "30px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: state.isSelected ? "white" : "black", // Change selected color here
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: "150px",
      marginLeft: "30px",
      zIndex: "999",
    }),
  };

  useEffect(() => {
    handleChangeCommom("", "", filterData);
  }, [debouncedSearchValue, page, countPerPage, statusValue]);

  useEffect(() => {
    const handleGetSalesPatner = async () => {
      try {
        const response = await ApiGet(`user/get`);
        const data = response?.data?.payload?.data;
        if (data && Array.isArray(data)) {
          const optionData = data.map((item) => ({
            value: item?.userId,
            label: item?.name,
          }));

          setUserName(optionData);
        }
      } catch (error) {
        toast.error("Vertriebspartner konnten nicht abgerufen werden..");
      }
    };

    const handleGetProject = async () => {
      await ApiGet(`project/get`)
        .then((response) => {
          const data = response?.data?.payload.data;
          const optionData = data?.map((item) => ({
            value: item._id,
            label: item.projectname,
          }));
          setProjectOptions(optionData);
        })
        .catch((error) => {
          toast.error(error);
        });
    };
    handleGetSalesPatner();
    handleGetProject();
  }, []);

 

  useEffect(() => {
    if (mobileModel) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [mobileModel]);

  return (
    <div>
      {mobileModel && (
        <div className="Modelmobile">
          <div className="modelinnercontent">
            <Modal.Header
              className="justify-content-end d-flex align-items-center sticky-header"
              style={{
                position: "sticky",
                top: "-1px",
                zIndex: "99999",
                backgroundColor: "#ffffff",
              }}
            >
              <i
                class="fa-sharp fa-solid fa-xmark "
                style={{fontSize:"24px"}}
                onClick={() => setMobileModel(!mobileModel)}
              ></i>
            </Modal.Header>
            <Modal.Body>
              <table
                className="table table-bordered "
                style={{ width: "100px", maxWidth: "100px", minWidth: "100px" }}
              >
                <tbody>
                  <tr>
                    <th scope="row">Datum:</th>
                    <td style={{ width: "100px" }}>
                      <Moment
                        format="DD-MM-YYYY , HH:mm:ss"
                        tz="CET"
                        date={mobileDatas.createdAt}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Unique Id:</th>
                    <td>{mobileDatas?.userId ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Benutzername:</th>
                    <td>{mobileDatas?.benutzername ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Projektname </th>
                    <td>{mobileDatas?.projectName ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Anrede:</th>
                    <td className="text-break">
                      {mobileDatas.contractions
                        ? mobileDatas.contractions
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Vorname:</th>
                    <td>{mobileDatas.name ? mobileDatas.name : "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nachname:</th>
                    <td>{mobileDatas.nachname ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Addresse:</th>
                    <td>{mobileDatas.strabe ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Postleitzahl:</th>
                    <td>{mobileDatas?.pLZ ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Ort:</th>
                    <td>{mobileDatas?.location ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Telephone:</th>
                    <td>{mobileDatas?.telephon ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email:</th>
                    <td>{mobileDatas?.email ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Energieverbrauch:</th>
                    <td>{mobileDatas?.power_consumption ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Haustyp:</th>
                    <td>{mobileDatas?.housetype ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">dachtyp:</th>
                    <td>{mobileDatas?.satteldach ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Dachbelegung:</th>
                    <td>{mobileDatas?.roofFelt ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Störfaktoren:</th>
                    <td>
                      {mobileDatas.schornstein
                        ? mobileDatas.schornstein.toString()
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">E-Auto:</th>
                    <td>{mobileDatas?.aktuellkeineAuto ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Wärmepumpe:</th>
                    <td>{mobileDatas?.rooftype ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">wichtige Informationen:</th>
                    <td>
                      {mobileDatas?.notizen &&
                      mobileDatas?.notizen != "undefined"
                        ? mobileDatas?.notizen
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Sales Patners:</th>
                    <td>
                      {mobileDatas?.patners
                        ? mobileDatas?.patners
                            ?.map((item) => item.name)
                            .join(",")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Unique Tracking Id:</th>
                    <td>{mobileDatas?.uniqueTrackingId ?? "-"}</td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>
          </div>
        </div>
      )}

      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className=" col-lg-4 d-flex align-items-center gap-20">
              <div className="date-filter-mobile-p">
                <h2 className="pl-3 pt-2 mr-5">WattFox Leads</h2>
                <div className="mobile">
                  <DateFilter onApply={handleApply} />
                </div>
              </div>
              <div className="web">
                <DateFilter onApply={handleApply} />
              </div>
            </div>

            <div className="col-lg-8 ">
              <div className="row mobile-view-alignment">
                <div className="col-lg-3 mobile-view">
                  <div className="project-Dropdown">
                    <Select
                      className=" "
                      // menuIsOpen={true}
                      value={selectedProject}
                      name="projectName"
                      onChange={(e) => handleChangeCommom(e, "projectName")}
                      options={projectOptions}
                      styles={ProjectStyle}
                      defaultValue={selectedProject}
                      placeholder="Projekt "
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className="col-lg-3 mobile-view2">
                  <div className="project-Dropdown">
                    <Select
                      className="dropdown-align "
                      value={selectedUserName}
                      name="userName"
                      onChange={(e) => {
                        handleChangeCommom(e, "userId");
                      }}
                      placeholder="Vertriebspartner"
                      options={userName}
                      maxHeight={"40px"}
                      defaultValue={selectedUserName}
                      isClearable={true}
                      isMulti
                      styles={customStyle}
                    />
                  </div>
                </div>
                <div className="col-lg-3 mobile-view3">
                  <div className="project-Dropdown">
                    <Select
                      className="dropdown-align "
                      value={statusValue}
                      name="userName"
                      onChange={(e) => {
                        handleOptions(e);
                      }}
                      placeholder="Status"
                      options={statusOptions}
                      maxHeight={"40px"}
                      isClearable={true}
                      styles={customStyle}
                    />
                  </div>
                </div>

                <div className="col-lg-3 mobile-view4">
                  <div>
                    <input
                      type="search"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        setLoading(true);
                      }}
                      name="search"
                      value={searchValue}
                      placeholder="Suche"
                    />
                  </div>
                </div>
              </div>
            </div>
           
          </div>

          <div className="web-table">
            <DataTable
              data={getNewsData}
              columns={columns}
              responsive
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              className="new_data__table table_height"
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
              paginationDefaultPage={page}
              progressPending={loading}
              progressComponent={
                <div className="LoadinComponent">
                  <TailSpin color="#334D52" height={30} width={30} />
                </div>
              }
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
              fixedHeader
            />
          </div>
          <div className="new-mobile-table">
            <DataTable
              data={getNewsData}
              columns={mobileData}
              responsive
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              className="new_data__table table_height"
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
              paginationDefaultPage={page}
              progressPending={loading}
              progressComponent={
                <div className="LoadinComponent">
                  <TailSpin color="#334D52" height={30} width={30} />
                </div>
              }
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
              fixedHeader
            />
          </div>
        </div>
      </div>
      {close && (
        <div className="new__imagemodel">
          <div className="body_model"></div>
          <div className="new-white-box">
            <div className="header_close">
              <i
                onClick={() => {
                  setclose(!close);
                  setimage("");
                }}
                className="fas fa-window-close modalClose"
              ></i>
            </div>
            <div className="image_body">
              {fileType == "svg" ||
              fileType == "png" ||
              fileType == "jpg" ||
              fileType == "jpeg" ? (
                <>
                  <img className="modal-image" src={image} />
                </>
              ) : fileType === "pdf" ? (
                <>
                  <object
                    data={image}
                    type="application/pdf"
                    width="420px"
                    height="650px"
                  ></object>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
